<!-- 产品分类 -->
<template>
    <div class="setBox">
        <!-- 第一行 搜索栏 -->
        <div class="table" style="height:calc( 100% - 80px );margin-top:30px;overflow-y:auto">
            <div class="block">
                <el-button type="text" icon="el-icon-plus" style="margin-left:5px" @click="() => add()">
                    新建一级菜单
                </el-button>
                <el-tree :data="list" node-key="id" default-expand-all :expand-on-click-node="false">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span style="font-weight: bold;">{{ data.name }}</span>
                        <span>
                            <!-- <i class="el-icon-top" style="color:#6391FF" @click="handleUp(node, data)"></i>
                            <i class="el-icon-bottom" style="margin-left:30px;color:#6391FF" @click="handleDown(node, data)"></i> -->
                            <el-button type="text" size="small" @click="() => append(node, data)" style="margin-left:50px;color:#67C23A">
                                新建子菜单
                            </el-button>
                            <el-button type="text" size="small" @click="() => remove(node, data)" style="margin-left:50px;color:#F56C6C">
                                删除菜单
                            </el-button>
                            <el-button type="text" size="small" @click="() => edit(node,data)" style="margin-left:50px">
                                编辑菜单
                            </el-button>
                        </span>
                    </span>
                </el-tree>
            </div>
        </div>

        <!-- 添加和编辑框 -->
        <el-dialog custom-class="nDialog" :visible="dialogOpen" title="新建菜单" :close-on-click-modal="false" width="600px" @close=" dialogOpen=false " :append-to-body="true" :destroy-on-close="true">
            <el-form ref="formAuth" :model="detail" :rules="rules" @submit.native.prevent label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="detail.name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="path" prop="url">
                    <el-input v-model="detail.url" size="small"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogOpen=false">取 消</el-button>
                <el-button size="small" type="primary" @click="save(detail.pid)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            detail: {
                pid: 0
            },
            dialogTitle: "",
            dialogOpen: false,
            rules: {
                name: [{
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }],
                type: [{
                    required: true,
                    message: '请输入type',
                    trigger: 'blur'
                }],
            }
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            this.http.post('/admin.SystemRoleAct/indexTree').then(response => {
                this.list = response
            })
        },
        // 排序向上
        handleUp(node, data) {
            console.log(node);
            var index = -1;
            let listTemp = [];
            if (parseInt(node.level) == 1) {
                listTemp = node.parent.data;
            } else {
                listTemp = node.parent.data.children;
            }
            for (var i in listTemp) {
                var subClass = listTemp[i];
                if (subClass == data) {
                    index = parseInt(i);
                }
            }
            //判断当前对象是不是大于第0个
            if (index > 0) {
                //先复制一份
                var copy = JSON.parse(JSON.stringify(data));
                listTemp.splice(index, 1); //删除
                listTemp.splice(index - 1, 0, copy); //插入
                this.sortRequest(listTemp);
            }
        },
        // 排序向下
        handleDown(node, data) {
            let listTemp = [];
            if (parseInt(node.level) == 1) {
                listTemp = node.parent.data;
            } else {
                listTemp = node.parent.data.children;
            }
            var index = listTemp.length;
            for (var i in listTemp) {
                var subClass = listTemp[i];
                if (subClass == data) {
                    index = parseInt(i);
                }
            }
            //判断当前对象是不是大于第0个
            if (index < listTemp.length - 1) {
                //先复制一份
                var copy = JSON.parse(JSON.stringify(data));
                listTemp.splice(index, 1);				//删除
                listTemp.splice(index + 1, 0, copy);		//插入
                this.sortRequest(listTemp);
            }
        },
        sortRequest(list) {
            var submitList = [];
            for (var i in list) {
                submitList.push({
                    view_sort: i,
                    id: list[i].id,
                });
            }
            this.http.post('/admin/permissions/setOrder', { list: submitList })
        },

        append(node, data) {
            this.detail = {
                pid: data.id
            }
            this.dialogOpen = true;
        },

        edit(node, data) {
            this.detail = JSON.parse(JSON.stringify(data));
            this.dialogOpen = true;
        },

        remove(node, data) {
            this.$confirm(`确定删除分类'${data.name}'吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.SystemRoleAct/delete', { id: data.id }).then(() => {
                    this.$message.success('删除成功!');
                    this.getList();
                })
            })
        },

        add() {
            this.detail = {
                pid: 0
            }
            this.dialogOpen = true;
        },

        save(pid = 0) {
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    this.http.post('/admin.SystemRoleAct/save', {
                        id: this.detail.id,
                        pid,
                        name: this.detail.name,
                        url: this.detail.url,
                        act_type:1
                    }).then(() => {
                        this.$message.success('添加成功!');
                        this.dialogOpen = false;
                        this.getList();
                    })
                }
            })
        },
    }
}
</script>

<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    /* padding-right: 8px; */
}
.el-tree-node.is-expanded > .el-tree-node__children span {
    /* padding-left: 7px; */
    font-weight: normal !important;
}
</style>
